// ** React Imports
import { useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

// ** Icons Imports
import ChevronUp from "mdi-material-ui/ChevronUp";
import ChevronDown from "mdi-material-ui/ChevronDown";
import TwitterContainer from "../TwitterContainer/TwitterContainer";

const CardWithCollapse = (members) => {
	// ** State
	const [collapse, setCollapse] = useState(false);

	const handleClick = () => {
		setCollapse(!collapse);
	};


	return (
		<Card>
			{/* <CardMedia sx={{ height: "14.5625rem" }}  image="../../component/cards/paper-boat.png"  /> */}
			<CardActions className='card-action-dense'>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Typography variant='h6' sx={{ marginBottom: 2.3, mt: 2.5, marginLeft: 1,
						lineHeight: 1.0,
						fontWeight: 600,
						fontSize: "2.5rem !important",
					}}>
							Updates
					</Typography>
					<IconButton size='large' onClick={handleClick}>
						{collapse ? <ChevronUp sx={{ fontSize: "1.875rem" }} /> : <ChevronDown sx={{ fontSize: "1.875rem" }} />}
					</IconButton>
				</Box>
			</CardActions>
			<Collapse in={collapse}>
				<Divider sx={{ margin: 0 }} />
				<CardContent>
					<Typography variant='body2'>
						{`${new Date().toLocaleDateString()}`}
					</Typography>
				</CardContent>
			</Collapse>
			<Collapse in={collapse}>
				<Divider sx={{ margin: 0 }} />
				<CardContent>
					<Typography variant='body2'>
            This section will let you know that latest updates from Castlemilk Pantry.
					</Typography>
				</CardContent>
			</Collapse>
			<Divider sx={{ marginTop: 2, marginBottom: 2 }} />
			<CardContent>
				<TwitterContainer />

			</CardContent>

		</Card>
	);
};

export default CardWithCollapse;
