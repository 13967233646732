import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import AlertTitle from "@mui/material/AlertTitle";
import Iconify from "../components/iconify";
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers } from "../redux/usersTableSlice";
import { Divider } from "@mui/material";

export default function ScanPage() {
	const dispatch = useDispatch();
	const USERLIST = useSelector((state) => state.data.userTable.users);
	const [data, setData] = useState("Scanning");
	const [openDialog, setOpenDialog] = useState(false);
	const [openBackDrop, setOpenBackDrop] = useState(false);
	const [userId, setUserId] = useState(null);
	const [isPaymentSuccessSnackbarOpen, setIsPaymentSuccessSnackbarOpen]
    = useState(false);
	const [isPaymentErrorSnackbarOpen, setIsPaymentErrorSnackbarOpen]
    = useState(false);
	const [isMembershipPaid, setIsMembershipPaid] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [paymentUserId, setPaymentUserId] = useState(null);

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	const handleMultiPayment = (referral, shouldCloseDialog) => {
		if (userId) {
			handlePayment([userId, referral], shouldCloseDialog);
		} else {
			console.log("No userId available");
		}
	};

	let findUser = USERLIST.find((user) => user.qrcode === data);

	const handlePayment = async (paymentData, shouldCloseDialog) => {
		setOpenBackDrop(true);
		try {
			const response = await fetch("/api/new-payment", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(paymentData),
			});
			if (!response.ok) {
				throw new Error("Failed to insert payment");
			}
			const data = await response.json();
			setOpenBackDrop(false);
			if (shouldCloseDialog) {
				setOpenDialog(false);
			} else {
				setOpenDialog(true);
			}
			if (paymentData[1] === "membership") {
				setIsMembershipPaid(true);
			}
			setIsPaymentSuccessSnackbarOpen(true);
			dispatch(fetchUsers());
			setPaymentUserId(userId);
			return { data, userId: paymentData[0] };
		} catch (error) {
			setIsPaymentErrorSnackbarOpen(true);
			setErrorMessage(error);
			setOpenBackDrop(false);
			throw new Error(error.message);
		}
	};

	useEffect(() => {
		if (findUser) {
			setUserId(findUser.user_id);
		}
	}, [findUser]);

	const handleCloseSnackbar = () => {
		setIsPaymentSuccessSnackbarOpen(false);
		setIsPaymentErrorSnackbarOpen(false);
	};

	const renderScanResultsDialog = () => {
		if (!findUser) {
			return null;
		} else if (findUser.payment_type === "weekly_shop") {
			return (
				<Dialog
					open={openDialog}
					onClose={handleCloseDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<Alert severity="error" variant="filled">
						<AlertTitle>Error</AlertTitle>—{" "}
						<Typography variant="h2">You already has done your shop</Typography>
					</Alert>

					<DialogActions>
						{
							<Button
								variant="outlined"
								color="error"
								onClick={handleCloseDialog}
							>
                Close
							</Button>
						}
					</DialogActions>
				</Dialog>
			);
		} else {
			return (
				<Dialog
					open={openDialog}
					onClose={handleCloseDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Scanning results"}
					</DialogTitle>
					<DialogContent>
						{renderUserStatusAlerts()}
						<Divider />
						{renderDialogContent()}
					</DialogContent>
					<DialogActions>{renderDialogActionButtons()}</DialogActions>
				</Dialog>
			);
		}
	};

	const renderUserStatusAlerts = () => {
		if (!findUser) {
			return null;
		}
		switch (findUser.status) {
			case "Idle":
				return (
					<Alert variant="filled" severity="info">
            Pay the membership fee to continue!
					</Alert>
				);
			case "Active":
				return (
					<Alert variant="filled" severity="success">
            Let's continue with the payment!
					</Alert>
				);
			case "Active-multi-payment":
				return (
					<Alert variant="filled" severity="success">
            Let's continue with this payment!
					</Alert>
				);
			case "Inactive":
				return (
					<Alert variant="filled" severity="warning">
            Inactive due to non-use, payment Data for a new membership!
					</Alert>
				);
			case "Expired":
				return (
					<Alert variant="filled" severity="error">
            Expired, Pay for a new membership!
					</Alert>
				);
			default:
				return null;
		}
	};

	const renderDialogContent = () => {
		if (!findUser) {
			return null;
		}
		switch (findUser.status) {
			case "Idle":
				return (
					<DialogContentText id="alert-dialog-description">
            Payment required.
					</DialogContentText>
				);
			case "Active":
				return (
					<DialogContentText id="alert-dialog-description">
            Thanks for coming!
					</DialogContentText>
				);
			case "Active-multi-payment":
				return (
					<DialogContentText id="alert-dialog-description">
            Thanks for coming referral shopper!
					</DialogContentText>
				);
			case "Inactive":
				return (
					<DialogContentText id="alert-dialog-description">
            Offer to make a buy membership.
					</DialogContentText>
				);
			case "Expired":
				return (
					<DialogContentText id="alert-dialog-description">
            Offer to make a buy membership.
					</DialogContentText>
				);
			default:
				return null;
		}
	};

	const renderDialogActionButtons = () => {
		if (!findUser) {
			return null;
		}
		switch (findUser.status) {
			case "Idle":
				return (
					<>
						<Button variant="outlined" onClick={handleCloseDialog}>
              Cancel
						</Button>
						<Button
							variant="contained"
							onClick={() => handleMultiPayment("membership", false)}
							autoFocus
							disabled={isMembershipPaid}
						>
              Pay membership
						</Button>
						<Button
							variant="contained"
							onClick={() => handleMultiPayment("weekly_shop", true)}
							disabled={!isMembershipPaid}
						>
              Pay
						</Button>
					</>
				);
			case "Active":
				return (
					<>
						<Button variant="outlined" onClick={handleCloseDialog}>
              Disagree
						</Button>
						<Button
							variant="contained"
							onClick={() => handleMultiPayment("weekly_shop", true)}
							autoFocus
						>
              Pay
						</Button>
					</>
				);
			case "Active-multi-payment":
				return (
					<>
						<Stack sx={{ marginLeft: 2, marginRight: 2,  marginBottom: 2 }} spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
							<Button variant="outlined" onClick={handleCloseDialog}>
              Disagree
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() => handleMultiPayment("Glasgow city council", true)}
								autoFocus
							>
              Glasgow
							</Button>
							<Button
								color="error"
								variant="contained"
								onClick={() => handleMultiPayment("Govan Project", true)}
								autoFocus
							>
              Govan project
							</Button>
							<Button
								color="success"
								variant="contained"
								onClick={() => handleMultiPayment("Ardenglen Housing A", true)}
								autoFocus
							>
              Ardenglen
							</Button>
							<Button
								color="warning"
								variant="contained"
								onClick={() => handleMultiPayment("One off shop NM", true)}
								autoFocus
							>
              One off shop
							</Button>
							<Button
								color="info"
								variant="contained"
								onClick={() => handleMultiPayment("Parish Church C", true)}
								autoFocus
							>
              Parish Church C
							</Button>
							<Button
								color="secondary"
								variant="contained"
								onClick={() => handleMultiPayment("Glasgow Helps", true)}
								autoFocus
							>
              Glasgow Helps
							</Button>
							<Button
								color="success"
								variant="contained"
								onClick={() => handleMultiPayment("Gift Voucher", true)}
								autoFocus
							>
              Gift Voucher
							</Button>
							<Button
								color="error"
								variant="contained"
								onClick={() => handleMultiPayment("Church of Christ", true)}
								autoFocus
							>
              Church of Christ
							</Button>
							<Button
								color="warning"
								variant="contained"
								onClick={() => handleMultiPayment("NVHA", true)}
								autoFocus
							>
              NVHA
							</Button>
						</Stack>
					</>
				);
			case "Inactive":
				return (
					<>
						<Button variant="outlined" onClick={handleCloseDialog}>
              Cancel
						</Button>
						<Button
							variant="contained"
							onClick={() => handleMultiPayment("membership", false)}
							autoFocus
							disabled={isMembershipPaid}
						>
              Pay membership
						</Button>
						<Button
							variant="contained"
							onClick={() => handleMultiPayment("weekly_shop", true)}
							disabled={!isMembershipPaid}
						>
              Pay
						</Button>
					</>
				);
			case "Expired":
				return (
					<>
						<Button variant="outlined" onClick={handleCloseDialog}>
              Cancel
						</Button>
						<Button
							variant="contained"
							onClick={() => handleMultiPayment("membership", false)}
							autoFocus
							disabled={isMembershipPaid}
						>
              Pay membership
						</Button>
						<Button
							variant="contained"
							onClick={() => handleMultiPayment("weekly_shop", true)}
							disabled={!isMembershipPaid}
						>
              Pay
						</Button>
					</>
				);
			default:
				return null;
		}
	};
	const commonStyles = {
		width: "px",
		height: "400px",
		paddingTop: 1,
	};

	return (
		<>
			<Helmet>
				<title>Dashboard: Scan Users</title>
			</Helmet>
			<Container>
				<Stack
					direction="row"
					alignItems="center"
					gap={1.5}
					justifyContent="left"
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
            Scan user
					</Typography>
					<Button
						variant="contained"
						startIcon={<Iconify icon="ic:twotone-qrcode" />}
					>
            Scan
					</Button>
				</Stack>
				<Card>
					<CardContent sx={{ bgcolor: "#ff8c94" }}>
						<Stack direction="column" alignItems="center">
							<Stack direction="column">
								<Box
									sx={{ ...commonStyles }}
									style={{
										// margin: "20px",
										width: "400px",
									}}
								>
									<QrReader
										ViewFinder={function noRefCheck() {}}
										constraints={{
											facingMode: "environment",
										}}
										onResult={(result, error) => {
											if (result) {
												setData(result?.text);
												setOpenDialog(true);
												if (error) {
													console.info(error);
												}
											}
										}}
										style={{ width: "100%" }}
									/>
								</Box>
								{renderScanResultsDialog()}
								{openBackDrop && (
									<Backdrop
										sx={{
											color: "#fff",
											zIndex: (theme) => theme.zIndex.drawer + 1,
										}}
										open={openBackDrop}
									>
										<CircularProgress color="inherit" />
									</Backdrop>
								)}
								{isPaymentErrorSnackbarOpen && (
									<Stack spacing={2} sx={{ width: "100%" }}>
										<Snackbar
											open={isPaymentErrorSnackbarOpen}
											autoHideDuration={6000}
											onClose={handleCloseSnackbar}
											anchorOrigin={{
												vertical: "top",
												horizontal: "center",
											}}
											sx={{
												top: "30px",
												position: "absolute",
											}}
										>
											<Alert
												onClose={handleCloseSnackbar}
												severity="error"
												sx={{ width: "100%" }}
											>
												<AlertTitle>Error</AlertTitle>
												<Divider />
                        Failed to insert payment of{" "}
												<Typography variant="h2">{findUser.name}</Typography>
												<Divider />
                        Membership Card{" "}
												<Typography variant="h3">
                          Error: {errorMessage}
												</Typography>
											</Alert>
										</Snackbar>
									</Stack>
								)}
								{isPaymentSuccessSnackbarOpen && (
									<Stack spacing={2} sx={{ width: "100%" }}>
										<Snackbar
											open={isPaymentSuccessSnackbarOpen}
											autoHideDuration={30000}
											onClose={handleCloseSnackbar}
											anchorOrigin={{
												vertical: "top",
												horizontal: "center",
											}}
											sx={{
												top: "10px",
												position: "absolute",
											}}
										>
											<Alert
												onClose={handleCloseSnackbar}
												severity="success"
												sx={{ width: "100%" }}
											>
												<AlertTitle>Success</AlertTitle>
												<Divider />
												<Typography variant="h2">{findUser.name}</Typography>
												<Divider />
                        Membership Card{" "}
												<Typography variant="h3">{paymentUserId}</Typography>
											</Alert>
										</Snackbar>
									</Stack>
								)}
							</Stack>
						</Stack>
					</CardContent>
				</Card>
			</Container>
		</>
	);
}
