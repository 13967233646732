import { useState } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import scotlandFlag from "../../../../public/assets/icons/ic_Scotland.svg";

// ----------------------------------------------------------------------

const LANGS = [
	{
		value: "en",
		label: "English",
		icon:  scotlandFlag ,
	},
	// {
	// 	value: "de",
	// 	label: "German",
	// 	icon: "/assets/icons/ic_flag_de.svg",
	// },
	// {
	// 	value: "fr",
	// 	label: "French",
	// 	icon: "/assets/icons/ic_flag_fr.svg",
	// },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
	const [open, setOpen] = useState(null);

	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	return (
		<>
			<IconButton
				onClick={handleOpen}
				sx={{
					padding: 0,
					width: 44,
					height: 44,
					...(open && {
						bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
					}),
				}}
			>
				<img src={LANGS[0].icon} alt={LANGS[0].label} />
			</IconButton>

			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: {
						p: 1,
						mt: 1.5,
						ml: 0.75,
						width: 180,
						"& .MuiMenuItem-root": {
							px: 1,
							typography: "body2",
							borderRadius: 0.75,
						},
					},
				}}
			>
				<Stack spacing={0.75}>
					{LANGS.map((option) => (
						<MenuItem key={option.value} selected={option.value === LANGS[0].value} onClick={() => handleClose()}>
							<Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

							{option.label}
						</MenuItem>
					))}
				</Stack>
			</Popover>
		</>
	);
}
