import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../firebase";

// @mui
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import LoadingButton from "@mui/lab/LoadingButton";
// components
import Iconify from "../../../components/iconify/Iconify";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

export default function LoginForm() {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const user = useSelector((state) => state.data.auth.user);

	useEffect(() => {
		if (user) {
			navigate("/dashboard/app", { replace: true });
		}
	}, [user, navigate]);

	const handleLogin = async () => {
		signInWithEmailAndPassword(auth, email, password)
			.then(() => {
				navigate("/dashboard/app", { replace: true });
			})
			.catch((error) => {
				console.error("Login failed:", error.message);
			});
	};

	return (
		<>
			<Stack spacing={3}>
				<TextField
					name="email"
					label="Email address"
					value={email.value}
					onChange={(e) => setEmail(e.target.value)}
				/>

				<TextField
					name="password"
					label="Password"
					type={password ? "text" : "password"}
					onChange={(e) => setPassword(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={() => setPassword(!password.value)}
									edge="end"
								>
									<Iconify
										icon={password ? "eva:eye-fill" : "eva:eye-off-fill"}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ my: 2 }}
			>
				<Checkbox name="remember" label="Remember me" />
				<Link variant="subtitle2" underline="hover">
          Forgot password?
				</Link>
			</Stack>

			<LoadingButton
				fullWidth
				size="large"
				type="submit"
				variant="contained"
				onClick={handleLogin}
			>
        Login
			</LoadingButton>
		</>
	);
}
