// ----------------------------------------------------------------------

const account = {
	displayName: "Kelly Burns",
	email: "kelly.burns@ardenglen.org.uk",
	photoURL: "../client/public/assets/images/avatars/avatar_default.jpg",
};

export default account;

// /assets/images/avatars/avatar_default.jpg